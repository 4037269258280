<template>
    <div>
        <navbar />
        <breadcrumbs></breadcrumbs>
        
        <div class="container">
        <all-transports />
        </div>

    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import AllTransports from '@/components/AllTransports.vue'

export default {
    components: {
        Navbar,
        AllTransports,
    },
}
</script>

<style>

</style>
