<template>
    <div>
            <!-- <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="vehicleStats">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#statsColapse" aria-expanded="true" aria-controls="statsColapse">
                        Statistika
                    </button>
                    </h2>
                    <div id="statsColapse" class="accordion-collapse collapse" aria-labelledby="vehicleStats" data-bs-parent="#accordionExample">
                        <div class="accordion-body"> -->
                            <request-status :loading="loading" :error="error" @reload="reloadRequest" />
                            <div v-if="vehiclesStats">
                                <ejs-grid
                                    ref="vehiclesStats"
                                    :dataSource="vehiclesStats" 
                                    class="vehicles-stats-grid"
                                    :allowFiltering='false'
                                    :allowSorting='true'
                                    :showColumnMenu='true'
                                    :allowSelection='false'
                                    :filterSettings='filterSettings'
                                    :allowPaging='false' 
                                    :pageSettings='pageSettings'
                                    :enablePersistence='false'
                                    :allowResizing='true'
                                    allowTextWrap='true'
                                    >
                                    <e-columns>
                                        <!-- <e-column field='id' headerText='ID' width='' textAlign='Left' :isPrimaryKey='true' :visible='true' :allowFiltering="false" :showColumnMenu="false"></e-column> -->
                                        <e-column field='vehicle.name' :template="vehicleLinkTemplate" headerText='Vozilo' width='150' textAlign="Left"></e-column>
                                        <e-column field='vehicle.plate_code' headerText='Tablice' width='120' textAlign="Left"></e-column>
                                        <e-column field='total_distance' :valueAccessor='numberCommaAccessor' headerText='Predjeno kilometara' width='138' textAlign="Right"></e-column>
                                        <e-column field='fuel_liters' :valueAccessor='numberCommaAccessor' headerText='Količina goriva' width='125' textAlign="Right"></e-column>
                                        <e-column field='fuel_total' :valueAccessor='numberCommaAccessor' headerText='Ukupno dinara' width='130' textAlign="Right"></e-column>
                                        <e-column field='avg_kmlit100' :valueAccessor='numberCommaAccessor' headerText='Prosečna potrošnja 100km/l' width='150' textAlign="Right"></e-column>
                                        <e-column field='avg_distance_price' headerText='Prosečna potrošnja 1km/din' width='140' textAlign="Right"></e-column>
                                        <e-column field='total_passengers' headerText='Prevezeno putnika' width='140' textAlign="Right"></e-column>
                                        <e-column field='price_passenger' headerText='Dinara po putniku' :valueAccessor='numberCommaAccessor' width='140' textAlign="Right"></e-column>
                                    </e-columns>

                                    <e-aggregates>
                                        <e-aggregate>
                                            <e-columns>
                                                <e-column type="Sum" field="distance"></e-column>
                                                <e-column type="Sum" field="fuel_liters"></e-column>
                                                <e-column type="Sum" field="fuel_total"></e-column>
                                                <e-column type="Custom" field="avg_kmlit100" :customAggregate="customAggregateAveragekm100"></e-column>
                                                <e-column type="Custom" field="avg_distance_price" :customAggregate="customAggregateAveragekm"></e-column>
                                                <e-column type="Sum" field="total_passengers"></e-column>
                                                <e-column type="Custom" field="price_passenger" :customAggregate="customAggregateAveragePrice_passenger"></e-column>
                                            </e-columns>
                                        </e-aggregate>
                                    </e-aggregates>
                                </ejs-grid> 
                            </div>


<!--                             
                        </div>
                    </div>
                </div>
            </div>
 -->

       
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import RequestStatus from '@/components/RequestStatus.vue';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate } from "@syncfusion/ej2-vue-grids";
    import VehicleLink from '@/components/grid-templates/VehicleLink.vue';
    import UtilService from '@/service/UtilService.js';


    export default {
        props: ['dateRange'],
        components: {
            RequestStatus,
        },
       provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate ]
        },
        data() {
            return {
                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},


                dateLower: UtilService.formatDatePattern(UtilService.getMoment().add(-90, 'day')),
                dateUpper: UtilService.formatDatePattern(UtilService.getMoment()),
                
                loading: false,
                error: null,
            }
        },
        

        computed: {
            ...mapState([
                'vehiclesStats',
            ]),
        },

        methods: {

            numberCommaAccessor(field, data) {
                const valueAccessor = data[field]
                if (isNaN(valueAccessor)) return valueAccessor;

                return valueAccessor ? this.numberWithCommas(valueAccessor) + " " + this.getSufix(field)  : '/';
            },

            getSufix(field) {
                if (field == 'total_distance') return 'km';
                if (field == 'fuel_liters') return 'l';
                if (field == 'fuel_total') return 'RSD';
                if (field == 'avg_kmlit100') return 'l/100km';
                if (field == 'avg_distance_price') return 'RSD';
                if (field == 'price_passenger') return 'RSD';
                return "";
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            async loadAllVehicleStats() {
                this.loading = true;
                this.error = null;

                let data = {
                    start_date: this.dateLower,
                    end_date: this.dateUpper,
                }

                await this.$store.dispatch('loadAllVehicleStats', data).catch((error) => {
                    this.error = error;
                });

                this.loading = false;
            },

            reloadRequest() {
                this.loadAllVehicleStats();
            },


            vehicleLinkTemplate() {
                return {
                    template: {
                        extends: VehicleLink
                    }
                }
            },

            updateRange(start, end) {
                this.dateLower = start.toISOString().slice(0,10);
                this.dateUpper = end.toISOString().slice(0,10);

                this.loadAllVehicleStats();
            },

            customAggregateAveragekm100(data){
                return this.customAggregateAverage(data, 'avg_kmlit100');
            },

            customAggregateAveragekm(data) {
                return this.customAggregateAverage(data, 'avg_distance_price');
            },

            customAggregateAveragePrice_passenger(data) {
                return this.customAggregateAverage(data, 'price_passenger');
            },

            customAggregateAverage(data, field) {
                let sum = 0;
                let notNull = 0;
                if (!Array.isArray(data))
                    data = data.result;

                data.forEach(element => {
                    sum += element[field]
                    if (element[field] != null) notNull += 1;
                });

                if (notNull > 0) return sum/notNull;
            },

            loadProps() {
                this.dateLower = this.dateRange[0].slice(0,10);
                this.dateUpper = this.dateRange[1].slice(0,10);
            },
        },


        created() {
            this.loadAllVehicleStats();
            this.loadProps();
        }

    }
</script>

<style>
    .vehicles-stats-grid .e-headercontent .e-table .e-columnheader {
        height: 60px;
    }
</style>