<template>
    <div>

        <request-status :loading="loading" :error="error" @reload="reloadRequest" />
        <div class="mt-3 d-flex justify-content-between">
            <div class="col-md-4">
                <div>
                <ejs-daterangepicker 
                    placeholder="Period"
                    v-model="dateRange"
                    :format="dateFormat"
                    :openOnFocus='true'
                    :change='rangeChanged'
                    :firstDayOfWeek=1
                    :serverTimezoneOffset=7.0
                    />
                </div>
            </div>


            <div class="btn-group" role="group" aria-label="Vehicles View">
                <input type="radio" class="btn-check" name="pivot-fuel-view" id="pivot-fuel-view1" value="Table" v-model="pivotFuelView" @change="viewChanged" autocomplete="off" checked>
                <label class="btn btn-outline-primary" for="pivot-fuel-view1"><font-awesome-icon size="2x" icon="table" /></label>

                <input type="radio" class="btn-check" name="pivot-fuel-view" id="pivot-fuel-view2" value="Chart" v-model="pivotFuelView" @change="viewChanged" autocomplete="off">
                <label class="btn btn-outline-primary" for="pivot-fuel-view2"><font-awesome-icon size="2x" icon="chart-bar" /></label>
            </div>
        </div>
        <div v-if="vehiclesFuelStats">
                <ejs-pivotview
                    ref="pivotFuel"
                    id="pivotFuel"
                    :gridSettings="gridSettings"
                    :width="width"
                    :height="height"
                    :allowDrillThrough="allowDrillThrough"
                    :showTooltip="showTooltip"
                    :showFieldList="showFieldList"
                    :actionFailure="actionFailure"
                    :showGroupingBar="showGroupingBar"
                    :groupingBarSettings="groupingBarSettings"
                    :chartSettings="chartSettings"
                    :displayOption="displayOption"
                    :locale="locale"
                > </ejs-pivotview>
        </div>

       
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import RequestStatus from '@/components/RequestStatus.vue';
    import VehicleLink from '@/components/grid-templates/VehicleLink.vue';
    import UtilService from '@/service/UtilService.js';
    import { FieldList, GroupingBar, PivotChart } from "@syncfusion/ej2-vue-pivotview";

    export default {
        components: {
            RequestStatus,
        },
       provide: {
            pivotview: [FieldList, GroupingBar, PivotChart ]
        },
        data() {
            return {
                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                
                loading: false,
                error: null,

                dataSourceSettings: {
                    values: [
                        { name: "avg_consumption", caption: "Potrosnja", type: "Avg" },
                        { name: "distance", caption: "Prešao", type: "Avg"},
                        { name: "km_price", caption: "Cena po km", type: "Avg" },
                    ],
                    formatSettings: [
                        { name: "Amount", format: "C0" },
                        { name: 'avg_consumption', useGrouping: false, minimumSignificantDigits: 1, maximumSignificantDigits: 3 },
                        { name: 'distance', useGrouping: false, minimumSignificantDigits: 1, maximumSignificantDigits: 3 },
                        { name: 'km_price', useGrouping: false, minimumSignificantDigits: 1, maximumSignificantDigits: 3 },
                    ],
                    columns: [
                        { name: "pump", caption: "Pumpa" }
                    ],
                    rows: [{ name: "vehicle" }, { name: "Vozilo" }],
                    filters: [
                        { name: "liters", caption: "Litara" },
                        { name: "total", caption: "Cena" },
                        { name: "total_distance", caption: "Kilometraža" },
                        { name: "fuel_type", caption: "Gorivo" },
                        { name: "payment_option", caption: "Plaćanje" },
                        { name: "driver", caption: "Vozač" },
                        { name: "created_at", caption: "Vreme" },
                    ],
                    expandAll: false,
                    enableSorting: true
                },
                showTooltip: true,
                gridSettings: { columnWidth: 140 },
                width: "100%",
                height: 500,
                showFieldList: false,
                allowDrillThrough: true,
                displayOption: { view: 'Table' },
                chartSettings: { chartSeries: { type: 'Column' } },
                showGroupingBar: false,
                showValuesButton: true,
                groupingBarSettings: { showFieldsPanel: true },
                pivotFuelView: 'Table',
                locale: 'en-US',


                dateRange: [
                    UtilService.getMoment().add(-90, 'day').toDate(),
                    UtilService.getMoment().toDate()
                ],
                dateFormat: "dd.MM.yyyy.",

            }
        },
        

        computed: {
            ...mapState([
                'vehiclesFuelStats',
            ]),
        },

        methods: {

            numberCommaAccessor(field, data) {
                return data[field] ? this.numberWithCommas(data[field]) : '/';
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            async loadAllVehicleFuelStats() {
                this.loading = true;
                this.error = null;


                let start = UtilService.dateToUTC(this.dateRange[0]).toISOString().slice(0,10);
                let end = UtilService.dateToUTC(this.dateRange[1]).toISOString().slice(0,10);


                let data = {
                    start_date: start,
                    end_date: end,
                }

                await this.$store.dispatch('loadAllVehicleFuelStats', data).catch((error) => {
                    this.error = error;
                });

                this.pivotRefreshData();
                this.loading = false;
            },

            reloadRequest() {
                this.loadAllVehicleFuelStats();
            },

            viewChanged() {
                let scheduleObj = this.$refs.pivotFuel.ej2Instances;

                scheduleObj.displayOption.view = this.pivotFuelView;
                if (this.pivotFuelView == "Table") {
                    scheduleObj.dataSourceSettings.properties = {...scheduleObj.dataSourceSettings.properties, ...this.dataSourceSettings};
                } else {
                    scheduleObj.dataSourceSettings.properties = {...scheduleObj.dataSourceSettings.properties, ...this.dataSourceSettings, formatSettings: []};
                }

                scheduleObj.pivotRefresh();

            },

            vehicleLinkTemplate() {
                return {
                    template: {
                        extends: VehicleLink
                    }
                }
            },


            rangeChanged() {
                this.loadAllVehicleFuelStats();
            },

            customAggregateAveragekm100(data){
                return this.customAggregateAverage(data, 'avg_kmlit100');
            },

            customAggregateAveragekm(data) {
                return this.customAggregateAverage(data, 'avg_distance_price');
            },

            customAggregateAveragePrice_passenger(data) {
                return this.customAggregateAverage(data, 'price_passenger');
            },

            customAggregateAverage(data, field) {
                let sum = 0;
                let notNull = 0;
                if (!Array.isArray(data))
                    data = data.result;

                data.forEach(element => {
                    sum += element[field]
                    if (element[field] != null) notNull += 1;
                });

                if (notNull > 0) return sum/notNull;
            },


            pivotRefreshData() {
                let scheduleObj = this.$refs.pivotFuel.ej2Instances;
                // console.log(scheduleObj);

                this.vehiclesFuelStats.forEach(x=> {
                    if (isNaN(x.distance)) {
                        x.distance = undefined
                        x.avg_consumption = undefined
                        x.km_price = undefined
                    }
                    x.payment_option = UtilService.translatePayOpt(x.payment_option);
                    x.fuel_type = UtilService.translateFuelType(x.fuel_type);
                    x.driver = x.driver_obj.first_name + " " + x.driver_obj.last_name;
                    x.created_at = UtilService.formatDateTime(x.created_at);
                    delete x.deleted;
                    delete x.modified_by;
                    delete x.created_by;
                    delete x.updated_at;
                    delete x.comment;
                    delete x.driver_obj;
                    delete x.id;
                })

                if (this.pivotFuelView == "Table") {
                    scheduleObj.dataSourceSettings.properties = {...scheduleObj.dataSourceSettings.properties, ...this.dataSourceSettings};
                } else {
                    scheduleObj.dataSourceSettings.properties = {...scheduleObj.dataSourceSettings.properties, ...this.dataSourceSettings, formatSettings: []};
                }

                scheduleObj.dataSourceSettings.dataSource = this.vehiclesFuelStats;
                scheduleObj.refresh();
                scheduleObj.pivotRefresh();

            },

            actionFailure(e) {
                console.log(e);
            }
        },


        created() {
            this.loadAllVehicleFuelStats();
        }

    }
</script>

<style>
    .vehicles-stats-grid .e-headercontent .e-table .e-columnheader {
        height: 60px;
    }
</style>