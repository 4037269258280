<template>
    <div>
        <modal-new-gas-station @confirm="confirmModalGasStation" inputId="modal-new-gas-station" />

        <div class="row">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Pumpe za gorivo
                    </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        
                        <button type="button" class="btn btn-block btn-primary btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#modal-new-gas-station"><i class="fas fa-plus-circle fa-lg me-1" /> Dodaj pumpu</button>

                        <request-status :loading="loading" :error="error" @reload="reloadRequest" />


                        <ul class="list-group" v-if="gasStations">
                            
                            
                            <li v-for="gasStation in gasStations" :key="gasStation.id" class="list-group-item" aria-disabled="true">
                                <font-awesome-icon icon="gas-pump" />
                                {{gasStation.name}}</li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import { Modal } from 'bootstrap';
    import ModalNewGasStation from '@/components/modals/transport/ModalNewGasStation.vue';
    import RequestStatus from '@/components/RequestStatus.vue';

    export default {
        components: {
            ModalNewGasStation,
            RequestStatus,
        },

        data() {
            return {
                newGasStationModal: null,
                loading: false,
                error: null,
            }
        },

        computed: {
            ...mapState([
                'gasStations',
            ]),
        },


        methods: {
            confirmModalGasStation() {
                this.newGasStationModal.hide();
            },




            reloadRequest() {
                this.loadAllGasStations();
            },

            async loadAllGasStations() {

                this.loading = true;
                this.error = null;

                await this.$store.dispatch('loadAllGasStations').catch((error) => {
                    this.error = error;
                });

                this.loading = false;
            }

        },
        
        mounted() {
            this.newGasStationModal = new Modal(document.getElementById('modal-new-gas-station'));
        },

        created() {
            this.loadAllGasStations();
        }
    }
</script>

<style>

</style>