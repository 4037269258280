<template>

        <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                        Nova pumpa
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <form id="new-gas-station-form">
                            <div class="mb-3 form-floating" v-if="gasStation">
                                <input type="text" class="form-control" id="pumpId" disabled v-model="gasStation.id" placeholder="Id pumpe">
                                <label for="pumpId">Id pumpe</label>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="fiel-liters" v-model="gasStationForm.name" placeholder="Naziv" required>
                                <label for="fiel-liters">Naziv</label>
                                <div class="invalid-feedback">
                                    Naziv pumpe.
                                </div>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="pumpComment" v-model="gasStationForm.comment" placeholder="Komentar">
                                <label for="pumpComment">Komentar</label>
                            </div>

                            
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
                        <button type="button" class="btn btn-primary" @click="createPump" >{{gasStation?'Izmeni':'Dodaj'}}</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import {mapState} from 'vuex'
    import TransportService from '@/service/TransportService.js'

    export default {

        props: {
            inputId: {
                type: String,
                default: "",
            },

            gasStation: {
                type: Object,
                default: null,
            },

            transportVehicle: {
                type: Object,
                default: null,
            }
        },

        data() {
            return {
                gasStationForm: {}
            }
        },
        
        computed: {
            ...mapState([
                'user'
            ]),
            
        },


        watch:{
            gasStation(){
                this.updateProp();
            }
        },

        created() {
            this.updateProp()
        },

        methods: {
            updateProp() {
                if (this.gasStation) {
                    this.gasStationForm.id = this.gasStation.id;
                    this.gasStationForm.name = this.gasStation.name;
                    this.gasStationForm.comment = this.gasStation.comment;
                    this.gasStationForm.deleted = this.gasStation.deleted;
                }
                
            },

            async createPump() {
                let form = document.getElementById("new-gas-station-form");
                console.log(form);
                form.classList.add('was-validated');
                if (!form.checkValidity())
                    return;

                if (this.gasStation) {
                    await this.updateFuel();
                }
                else {
                    await TransportService.createGasStation(this.gasStationForm)
                    .then(() => {

                        this.toast("Dodavanje pumpe uspešno.");
                        this.$emit('confirm');

                    })
                    .catch((error) => alert(error.message));    
                }

                this.$store.dispatch('loadAllGasStations');

            },

            async updateFuel() {
                    await TransportService.updateGasStation(this.gasStationForm)
                    .then(() => {
                        this.toast("Uspešna izmena.");
                        this.$emit('confirm');
                    })
                    .catch((error) => alert(error.message))
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            }
        },

    }
</script>

<style>

</style>