<template>
    <router-link :to="{ name: 'transport-vehicle', params: { tvId: data.vehicle.id }}">
    <span>{{ data.vehicle.name }}</span>
    </router-link>
        
</template>

<script>
export default {
    name: 'grid-row-index',

    data: function() {
        return {
            data: {},
        }
    },
}
</script>
