<template>
    <div class="container">
        <modal-new-transport-venhicle @confirm="confirmModalTransport" inputId="modal-new-tv"  />

        <div class="title-header">
            <div class="row px-3">
                <div class="col-sm-5">
                    <h2>Prevozi</h2>
                </div>

                <div v-if="user.role === 'ADMIN'" class="col-sm-7">
                    <div class="ui-button">
                        <button type="button" class="btn btn-block btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#modal-new-tv"><i class="fas fa-plus-circle fa-lg me-1" /> Dodaj novo prevozno sredstvo</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- <trips-accordion class="mt-3" v-if="userTodayTrips && userTodayTrips.length > 0" /> -->
            

        <div v-if="user.role === 'ADMIN'" class="mt-3 d-flex justify-content-between">
            
            <div class="col-md-4">
                <div v-show="vehicleView == 'STATS'">
                <ejs-daterangepicker 
                    placeholder="Period"
                    v-model="dateRange"
                    :format="dateFormat"
                    :openOnFocus='true'
                    :change='rangeChanged'
                    :firstDayOfWeek=1
                    :serverTimezoneOffset=7.0
                    />
                </div>
            </div>

            <div class="btn-group" role="group" aria-label="Vehicles View">
                <input type="radio" class="btn-check" name="vehicle-view" id="vehicle-view1" value="GRID" v-model="vehicleView" autocomplete="off" checked>
                <label class="btn btn-outline-primary" for="vehicle-view1"><font-awesome-icon size="2x" icon="th" /></label>

                <input type="radio" class="btn-check" name="vehicle-view" id="vehicle-view2" value="STATS" v-model="vehicleView" autocomplete="off">
                <label class="btn btn-outline-primary" for="vehicle-view2"><font-awesome-icon size="2x" icon="bars" /></label>
            </div>
        </div>

        <div class="row" v-if="vehicleView == 'GRID'">  

            <request-status :loading="loading" :error="error" @reload="reloadRequest" />

            <template v-for="vehicle in availableVehicles">
                <div :key="vehicle.id" class="col-md-4 card-box">


                    <router-link class="card" :to="{ name: 'transport-vehicle', params: { tvId: vehicle.id }}">
                    <div class="card-body" :class="{'warning-card': vehicle.warnings && vehicle.warnings.registration_end != null && vehicle.warnings.registration_end >= 0, 'danger-card':  vehicle.warnings && vehicle.warnings.registration_end && vehicle.warnings.registration_end < 0}">

                        <div class="code-title">
                        <span class="badge bg-secondary">{{vehicle.plate_code}}</span>
                        </div>
                        
                        <h5 class="card-title"><font-awesome-icon icon="car" /> {{vehicle.name}}</h5>
                        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                    </router-link>

                </div>
            </template>
            <h5 v-if="availableVehicles && availableVehicles.length == 0">Nemate dodeljeno ni jedno vozilo</h5>
        </div>

        <vehicle-stats ref="vehicleStats" :dateRange="dateRange" v-show="vehicleView == 'STATS'" />

        <vehicle-fuel-stats ref="vehicleFuelStats" :dateRange="dateRange" />

        <gas-stations v-if="user.role === 'ADMIN'" class="mt-5 mb-5" />
        
    </div>
    
</template>


<script>

    import {mapState} from 'vuex'
    import RequestStatus from '@/components/RequestStatus.vue';
    import ModalNewTransportVenhicle from '@/components/modals/ModalNewTransportVenhicle.vue';
    import { Modal } from 'bootstrap';
    import GasStations from '@/components/transport/GasStations.vue';
    // import TripsAccordion from '@/components/transport/TripsAccordion.vue';
    import UtilService from '@/service/UtilService.js';
    import VehicleStats from '@/components/transport/VehicleStats.vue';
    import VehicleFuelStats from '@/components/transport/VehicleFuelStats.vue';

    // import UtilService from '@/service/UtilService.js';

    export default {
        components: {
            ModalNewTransportVenhicle,
            RequestStatus,
            GasStations,
            VehicleStats,
            VehicleFuelStats
        },

       
         data() {
            return {
                loading: false,
                error: null,
                newTransportModal: null,
                vehicles: undefined,

                vehicleView: "STATS",
                dateFormat: "dd.MM.yyyy.",

                dateRange: [
                    UtilService.formatDatePattern(UtilService.getMoment().add(-90, 'day')) + "T00:00:00",
                    UtilService.formatDatePattern(UtilService.getMoment()) + "T00:00:00"
                ],
                
            }
        },
        computed: {
            ...mapState([
                'user',
                'allWorkOrders',
                'transportVehicles',
                'userTodayTrips',
            ]),

            availableVehicles() {
                if (this.user.role === "ADMIN") return this.transportVehicles;
                return this.vehicles;
            }
        },


        

        methods: {
            checkVehicleWarning: UtilService.checkVehicleWarning,

    
            async loadAllTransports() {
                this.loading = true;
                this.error = null;

                await this.$store.dispatch('loadAllTransportVehicles', this.workOrderForm).catch((error) => {
                    this.error = error;
                });

                this.loading = false;
            },

            confirmModalTransport() {
                this.newTransportModal.hide();
            },

            reloadRequest() {
                this.loadAllTransports();
            },
            
            async loadTrips() {
                await this.$store.dispatch('loadTodayUserTrips', {'scope': 'passenger'});
                this.calculateAvailbaleCars();
                this.calculateWarnings();
            },

            calculateAvailbaleCars() {
                let vehicles = [];

                this.userTodayTrips.forEach(trip => {

                    if (trip.driver == this.user.id && !vehicles.some(x => x.id === trip.vehicle))
                        vehicles.push(trip.vehicle_obj);

                });

                this.vehicles = vehicles;
            },
            calculateWarnings() {
                let self = this;
                this.availableVehicles.forEach(vehicle => {
                    self.$set(vehicle, 'warnings', this.checkVehicleWarning(vehicle));
                });
            },
            rangeChanged() {


                let start = UtilService.dateToUTC(this.dateRange[0])
                let end = UtilService.dateToUTC(this.dateRange[1])

                this.$refs.vehicleStats.updateRange(start, end)            },
        },


        created() {
            this.loadAllTransports();
            this.loadTrips();
        },

        mounted() {
            this.newTransportModal = new Modal(document.getElementById('modal-new-tv'));
        }


    }
</script>

<style scoped>

    .card {
        height: auto;
        margin-bottom: 10px;
        text-decoration: none;
        color: #313131;
        transition: all ease 0.4s;

    }

    .card:hover {
        background: #eee;
    }

    .warning-card {
        background: rgb(255, 240, 30);
    }

    .danger-card {
        background: rgb(255, 79, 79);
    }


</style>
